<script lang="ts">
import { useAccountStore,useAuthStore } from "@/stores";
import InputWrapper from "@/components/Input/InputWrapper.vue";


export default {
  components: {
    InputWrapper,
  },
  data() {
    return {
      accountStore: useAccountStore(),
      isChangeBusinessUserPasswordPopupVisible: this.visible,
      newPassword: "",
      newPasswordConfirmation: "",
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    // Watch for changes to the 'visible' prop from the parent component
    visible(value) {
      this.isChangeBusinessUserPasswordPopupVisible = value;
    },
  },
  methods: {
    emitClosePopup() {
      this.$emit("close-edit-dialog", this.isChangeBusinessUserPasswordPopupVisible);
      this.isChangeBusinessUserPasswordPopupVisible = false;
    },
    async updatePassword() {
      if (this.newPassword !== this.newPasswordConfirmation) {
        this.$toast.add({
          severity: "error",
          summary: "Failed",
          detail: `Passwords does not match`,
          life: 5000,
        });
        return;
      }

      await this.accountStore
        .updateBusinessUserPassword(this.userId, this.newPassword)
        .then(() => {
          this.isChangeBusinessUserPasswordPopupVisible = false;
          this.$toast.add({
            severity: "success",
            summary: "Updated",
            detail: "Password is updated",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Failed",
            detail: `Something went wrong: ${error}`,
            life: 5000,
          });
        });
    },
  },
};
</script>

<template>
  <PVPopup
    v-model:visible="isChangeBusinessUserPasswordPopupVisible"
    modal
    dismissableMask
    :draggable="false"
    header="Change Password"
    @hide="emitClosePopup"
  >
    <form id="change-password-form" @submit.prevent="updatePassword">
      <div class="input-container">
        <InputWrapper
          labelText="New Password"
          labelRef="password"
          v-model="this.newPassword"
          variant="grey"
          type="password"
          :isRequired="true"
        />
        <InputWrapper
          labelText="Repeat Password"
          labelRef="password-conf"
          v-model="this.newPasswordConfirmation"
          variant="grey"
          type="password"
          :isRequired="true"
        />
      </div>
    </form>
    <template #footer>
      <div class="btn-container">
        <PVButton
          class="btn btn-secondary"
          label="Cancel"
          @click="emitClosePopup"
          text
        />
        <PVButton
          class="btn btn-primary"
          label="Save"
          type="submit"
          autofocus
          form="change-password-form"
        />
      </div>
    </template>
  </PVPopup>
</template>