import { defineStore } from "pinia";
import type { User, AccountState } from "./types";

import { fetchWrapper, BASE_URL } from "@/helpers";

export const useAccountStore = defineStore({
  id: "account",
  state: (): AccountState => ({
    user: null,
  }),
  getters: {
    isUserAdmin: (state) => state?.user?.role === "admin",
    isUserEditor: (state) => state?.user?.role === "editor",
    isUserViewer: (state) => state?.user?.role === "viewer",
    userRole: (state) => state?.user?.role,
    getAccountId: (state) => state?.user?.accountId,
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    async getAccountData(accountId: string) {
      const user: User = await fetchWrapper.get(
        `${BASE_URL}/accounts/${accountId}`
      );

      this.user = user;

      return user;
    },
    async updatePassword(accountId: string, password: string) {
      return await fetchWrapper.patch(
        `${BASE_URL}/accounts/changePassword/${accountId}`,
        {
          password,
        }
      );
    },
    async updateEmail(accountId: string, email: string) {
      return await fetchWrapper.patch(
        `${BASE_URL}/accounts/changeEmail/${accountId}`,
        {
          email,
        }
      );
    },
    async updateSettings(fname: string, lname: string, companyName: string) {
      const userId = this.$state?.user?.accountId;

      const updatedUser = await fetchWrapper.patch(
        `${BASE_URL}/accounts/${userId}`,
        {
          fname,
          lname,
          companyName,
        }
      );
    },
    async updateBusinessUserPassword(userID: string, password: string) {
      return await fetchWrapper.patch(
        `${BASE_URL}/accounts/changeBusinessUserPassword/${userID}`,
        {
          password,
        }
      );
    },
  },
});
